<template>
  <div class="pa-3 grid-container">
    <div></div>
    <div>
      <div class="grid-container-totals mb-3">
        <b>SUBTOTAL</b>
        <b class="grid-end">{{ formatPrice(subtotal) }}</b>
      </div>
      <div class="grid-container-totals mb-3">
        <b>VAT {{ vatRate }}%</b>
        <b class="grid-end">{{ formatPrice(vat) }}</b>
      </div>
      <div class="grid-container-totals mb-3">
        <b>TOTAL</b>
        <b class="grid-end">{{ formatPrice(total) }}</b>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
const { mapGetters } = VuexNamespacedHelpers("creditNote");
import { isNil } from "lodash";

export default {
  name: "credit-note-totals",
  components: {},
  inject: ["$validator"],
  props: {
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["subtotal", "vatRate"]),
    vat() {
      return (this.subtotal / 100) * this.vatRate;
    },
    total() {
      return this.subtotal + this.vat;
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(-price) : null;
    }
  }
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-gap: 10px;
}
.grid-container-totals {
  display: grid;
  grid-template-columns: 7fr 3fr;
  font-size: 16px;
}
.grid-end {
  justify-self: end;
}
</style>

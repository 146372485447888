<template>
  <div>
    <v-card color="white">
      <v-toolbar dark color="primary" dense>
        <v-icon class="white--text">fas fa-file-invoice-dollar</v-icon>
        <v-toolbar-title class="white--text"
          ><span class="primary--text text--lighten-2">{{ state }} </span>
          CREDIT NOTE</v-toolbar-title
        >
        <v-spacer />
        <v-menu v-if="state === 'DRAFT'" bottom left class="pl-2">
          <v-btn slot="activator" dark icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-list light>
            <v-list-tile @click.stop="deleteDialog = true">
              <v-list-tile-title>Delete draft</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn
          v-if="state !== 'DRAFT'"
          :disabled="!pdfDownloadable"
          icon
          @click="downloadDialog = true"
        >
          <v-icon>fas fa-file-pdf</v-icon>
        </v-btn>
      </v-toolbar>
      <credit-note @savable="savable" :is-create="false" />
    </v-card>
    <v-fab-transition>
      <v-btn
        v-show="state === 'DRAFT'"
        color="success"
        :disabled="!canFinalize"
        round
        fixed
        bottom
        right
        large
        @click="openFinalizeDialog"
      >
        Finalize
      </v-btn>
    </v-fab-transition>
    <finalize-credit-note-dialog
      :dialog="finalizeDialog"
      :company-id="companyId"
      @close="finalizeDialog = false"
    />
    <download-credit-note-dialog
      :download-dialog="downloadDialog"
      :credit-note-id="creditNoteId"
      @close="downloadDialog = false"
    />
    <delete-credit-note-dialog
      :dialog="deleteDialog"
      :credit-note-id="creditNoteId"
      @close="deleteDialog = false"
      @delete-success="toInvoiceDetails"
    />
  </div>
</template>

<script type="text/babel">
import creditNoteModule from "@/store/modules/credit-note";
import { mapDetailViewLifecycleMethods } from "@/views/helpers";
import CreditNote from "../../../components/invoice/credit-note/CreditNote";
import { SAVE } from "../../../store/templates/crud/action-types";
import FinalizeCreditNoteDialog from "../../../components/invoice/credit-note/FinalizeCreditNoteDialog";
import DownloadCreditNoteDialog from "../../../components/invoice/credit-note/DownloadCreditNoteDialog";
import DeleteCreditNoteDialog from "../../../components/invoice/credit-note/DeleteCreditNoteDialog";
import { isNil } from "lodash";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "creditNote",
  creditNoteModule
);

export default {
  name: "credit-note-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  components: {
    DeleteCreditNoteDialog,
    DownloadCreditNoteDialog,
    FinalizeCreditNoteDialog,
    CreditNote
  },
  data: () => ({
    fab: false,
    canSave: false,
    finalizeDialog: false,
    downloadDialog: false,
    deleteDialog: false
  }),
  created() {
    this.$store.commit("creditNote/setVatRate", this.company.vatRate);
  },
  computed: {
    invoiceId() {
      return this.$store.getters["invoice/id"];
    },
    invoiceDue() {
      return this.$store.getters["invoice/due"];
    },
    creditNoteSubtotal() {
      return this.$store.getters["creditNote/subtotal"];
    },
    company() {
      return this.$store.getters["invoice/company"];
    },
    state() {
      return this.$store.getters["creditNote/state"];
    },
    creditNoteId() {
      return this.$store.getters["creditNote/id"];
    },
    pdfDownloadable() {
      return this.$store.getters["creditNote/pdfDownloadable"];
    },
    isCompanyView() {
      return !isNil(this.companyId);
    },
    canFinalize() {
      return this.invoiceDue >= this.creditNoteSubtotal;
    }
  },
  methods: {
    savable(val) {
      this.canSave = val;
    },
    createDraft() {
      this.$store.dispatch(`creditNote/${SAVE}`, this.invoiceId);
    },
    openFinalizeDialog() {
      this.finalizeDialog = true;
    },
    toInvoiceDetails() {
      this.$router.push({
        name: this.isCompanyView ? "company.invoices.show" : "invoices.show",
        params: { invoiceId: this.invoiceId, companyId: this.companyId }
      });
    }
  },
  ...detailViewLifecycleMethods
};
</script>
<style scoped>
.display-center {
  display: flex;
}
</style>

<template>
  <div class="mt-3">
    <div class="grid-container bottom-border header pb-2">
      <div class="item-start"><b>ITEM</b></div>
      <div class="item-end"><b>QUANTITY</b></div>
      <div class="item-end"><b>PRICE</b></div>
      <div class="item-end"><b>TOTAL</b></div>
    </div>
    <div>
      <p
        v-if="!(hasItems || hasTempItem) && !selecting"
        class="action-required bottom-border pa-2 ma-0"
      >
        No items.
      </p>
      <div v-if="hasItems" class="items-container">
        <div v-for="(item, i) in items" :key="i">
          <div v-if="itemEdit === i">
            <credit-note-item-edit
              :item="item"
              :ref="`edit-${i}`"
              :index="i"
              @confirm="itemEdited"
            />
          </div>
          <div v-else class="grid-container items pb-2 pt-2">
            <div class="description-grid">
              <p class="ma-0">{{ item.description }}</p>
              <section v-if="isCreate">
                <v-btn class="item-btn" icon
                  ><v-icon class="text-end" small @click="editItem(i)"
                    >edit</v-icon
                  ></v-btn
                ><v-btn icon class="item-btn"
                  ><v-icon class="text-end" small @click="deleteItem(i)"
                    >delete</v-icon
                  ></v-btn
                >
              </section>
            </div>
            <p class="text-end ma-0">{{ item.quantity }}</p>
            <p class="text-end ma-0">
              {{ formatPrice(item.unitPrice) }}
            </p>
            <p class="text-end ma-0">
              {{ formatPrice(itemTotalPrice(i)) }}
            </p>
          </div>
          <v-divider />
        </div>
      </div>
      <credit-note-item v-if="hasTempItem" />
      <div v-if="selecting" class="option-grid bottom-border">
        <v-btn color="primary" disabled>OFFERS</v-btn>
        <v-btn color="primary" @click="addTempItem">MANUAL</v-btn>
      </div>
      <v-fab-transition>
        <v-btn
          v-show="!selecting && !hasTempItem && itemEdit === null && isCreate"
          color="pink"
          fab
          dark
          small
          bottom
          class="add-button"
          @click="selecting = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers as VuexNamespacedHelpers } from "vuex";
const { mapGetters } = VuexNamespacedHelpers("invoice");
import { isNil } from "lodash";
import CreditNoteItem from "./CreditNoteItem";
import CreditNoteItemEdit from "./CreditNoteItemEdit";

export default {
  name: "credit-note-items",
  components: { CreditNoteItemEdit, CreditNoteItem },
  inject: ["$validator"],
  props: {
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selecting: false,
    itemEdit: null,
    editing: false
  }),
  computed: {
    ...mapGetters(["modelCompany"]),
    companySelected() {
      return !isNil(this.modelCompany);
    },
    items: {
      get() {
        return this.$store.getters["creditNote/items"];
      },
      set(newVal) {
        return this.$store.commit("creditNote/setItems", newVal);
      }
    },
    hasItems() {
      return this.items.length > 0;
    },
    hasTempItem() {
      return !isNil(this.$store.getters["creditNote/tempItem"]);
    }
  },
  watch: {
    selecting: {
      handler(newVal) {
        if (!newVal && !this.editing && this.hasItems && !this.hasTempItem) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    editing: {
      handler(newVal) {
        if (!newVal && !this.selecting && this.hasItems && !this.hasTempItem) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    hasItems: {
      handler(newVal) {
        if (!this.selecting && !this.editing && newVal && !this.hasTempItem) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    },
    hasTempItem: {
      handler(newVal) {
        if (!this.selecting && !this.editing && this.hasItems && !newVal) {
          this.$emit("savable", true);
        } else {
          this.$emit("savable", false);
        }
      },
      immediate: true
    }
  },
  methods: {
    addItem() {
      this.$store.commit("creditNote/addItem");
    },
    addTempItem() {
      this.selecting = false;
      this.$store.commit("creditNote/addTempItem");
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    },
    deleteItem(index) {
      this.$store.commit("creditNote/deleteItem", index);
    },
    editItem(index) {
      this.itemEdit = index;
      this.editing = true;
    },
    itemEdited() {
      this.itemEdit = null;
      this.editing = false;
    },
    itemTotalPrice(index) {
      return this.$store.getters["creditNote/itemTotalAmount"](index);
    }
  }
};
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.bottom-border {
  border-bottom: 1px solid lightgray;
}
.option-grid {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-gap: 10px;
}
.header {
  font-size: 18px;
}
.description-grid {
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-gap: 25px;
  align-items: center !important;
}
.items {
  font-size: 16px;
}
.item-end {
  justify-self: end;
}
.action-required {
  border-bottom: 1px solid lightgray;
  text-align: center;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }
}
.text-end {
  text-align: end;
}
.add-button {
  left: 50%;
}
.item-btn {
  background-color: #e8e8e8;
}
</style>

<template>
  <div class="grid-container">
    <v-text-field
      v-model="description"
      v-validate="'required|max:255'"
      label="Description"
      autocomplete="not-my-description"
      :counter="255"
      :error-messages="errors.collect('description')"
      data-vv-name="description"
      required
    />
    <v-text-field
      v-model="quantity"
      type="number"
      class="input-right"
      v-validate="'required|integer|min_value:1'"
      autocomplete="not-my-quantity"
      :error-messages="errors.collect('quantity')"
      data-vv-name="quantity"
      required
    />
    <v-text-field
      v-model="unitPrice"
      class="input-right"
      v-validate="'required|min_value:0.01|exceedInvoiceTotal'"
      autocomplete="not-my-unit-price"
      :error-messages="errors.collect('unitPrice')"
      data-vv-name="unitPrice"
      name="unitPrice"
      prefix="€"
      label="Price"
    />
    <div class="btn-grid">
      <v-btn color="grey" class="small-button" @click="remove"
        ><v-icon>far fa-trash-alt</v-icon></v-btn
      >
      <v-btn class="large-button" color="primary" @click="confirm"
        >CONFIRM</v-btn
      >
    </div>
  </div>
</template>

<script type="text/babel">
import { cloneDeep } from "lodash";
import { Validator } from "vee-validate";
import store from "@/store";

// eslint-disable-next-line
const exceedInvoiceTotal = value =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    const invoiceDue = store.getters["invoice/due"];
    let creditNoteTotalAmount = store.getters["creditNote/subtotal"];
    const tempItem = store.getters["creditNote/tempItem"];
    const tempItemTotalPrice = tempItem.unitPrice * tempItem.quantity;
    creditNoteTotalAmount += tempItemTotalPrice;
    if (invoiceDue >= creditNoteTotalAmount) {
      return resolve({
        valid: true
      });
    }

    return resolve({
      valid: false,
      data: {
        message: `The credit note exceeds the invoice total`
      }
    });
  });

Validator.extend("exceedInvoiceTotal", {
  validate: exceedInvoiceTotal,
  getMessage: (fields, params, data) => data.message
});

export default {
  name: "credit-note-item",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  computed: {
    item() {
      return this.$store.getters["creditNote/tempItem"];
    },
    unitPrice: {
      get() {
        return this.item.unitPrice;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.unitPrice = val;
        this.$store.commit("creditNote/setTempItem", item);
      }
    },
    description: {
      get() {
        return this.item.description;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.description = val;
        this.$store.commit("creditNote/setTempItem", item);
      }
    },
    quantity: {
      get() {
        return this.item.quantity;
      },
      set(val) {
        let item = cloneDeep(this.item);
        item.quantity = parseInt(val);
        this.$store.commit("creditNote/setTempItem", item);
      }
    }
  },
  methods: {
    async confirm() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("creditNote/addItem");
      this.$store.commit("creditNote/removeTempItem");
    },
    remove() {
      this.$store.commit("creditNote/removeTempItem");
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgray;
  align-items: center;
}
.btn-grid {
  display: grid;
  grid-template-columns: 8fr 2fr;
  align-items: center;
}
.input-right >>> input {
  text-align: right;
}
.small-button {
  min-width: 0;
  width: 50px;
}
.large-button {
  min-width: 0;
  width: 120px;
}
</style>

<script>
import { isNil, isFunction } from "lodash";

export default {
  name: "can-validate-mixin",
  methods: {
    validate(successCallback, failureCallback = null) {
      this.errors.clear();
      this.$validator.validateAll().then(async isEverythingValid => {
        if (isEverythingValid) {
          try {
            await successCallback();
          } catch (err) {
            console.debug("Server side validation failed", err);
            this.handleServerSideValidationErrors(err);
          }
        } else {
          if (!isNil(failureCallback) && isFunction(failureCallback)) {
            failureCallback();
          } else {
            this.focusOnFirstError();
          }
        }
      });
    },
    handleServerSideValidationErrors(err) {
      if (!isNil(err.errors)) {
        // Add all server side validation errors to the error bag.
        Object.entries(err.errors).forEach(([field, fieldErrors]) =>
          fieldErrors.forEach(msg =>
            this.errors.add({
              field,
              msg
            })
          )
        );
        this.isLoading = false;
        this.focusOnFirstError();
      }
    },
    focusOnFirstError() {
      if (this.errors.items.length > 0) {
        const fieldName = this.errors.items[0].field
          .replace(/\[]/g, "\\[\\]")
          .replace(/\./g, "\\.");

        const matches = document.querySelectorAll(
          `input[data-vv-name=${fieldName}], textarea[data-vv-name=${fieldName}]`
        );
        if (matches.length > 0) {
          matches[0].focus();
        } else {
          console.warn(
            `Tried to focus input with data-vv-name ${fieldName}, but none was found.`
          );
        }
      }
    }
  }
};
</script>

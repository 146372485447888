<template>
  <div
    :class="[
      state === 'DRAFT' ? 'header-wrapper-grey' : 'header-wrapper-blue',
      'pa-3'
    ]"
  >
    <div class="general-info">
      <v-icon class="credit-note-icon" color="black"
        >fad fa-file-invoice-dollar</v-icon
      >
      <div class="credit-note-info">
        <h1>{{ creditNoteState }} CREDIT NOTE</h1>
        <div class="text-xs-right">
          <p class="mb-1" v-for="(auditTrail, i) in auditTrails" :key="i">
            {{ auditTrail }}
          </p>
        </div>
      </div>
    </div>
    <div class="invoice-info mt-3 pa-3">
      <div class="info-row">
        <h1 class="text-uppercase">
          <mark class="info-color">{{ invoice.state }}</mark> INVOICE
          <mark class="info-color">{{ invoice.number }}</mark>
        </h1>
        <h1 class="end">
          TOTAL (VAT incl) :
          {{ formatPrice(invoice.totalAmount) }}
        </h1>
      </div>
      <div class="info-row">
        <h1 class="text-uppercase">
          {{ company.name }} -
          <mark class="info-color">{{ company.customerNumber }}</mark>
          <mark class="blacklisted" v-if="company.state === 'BLACKLISTED'">
            !BLACKLISTED!</mark
          >
        </h1>
        <h1 class="end">
          BALANCE (VAT incl) :
          {{ formatPrice(balance + (balance * company.vatRate) / 100) }}
        </h1>
      </div>
      <div class="info-row">
        <h1 class="text-uppercase">{{ company.billingData.vatNumber }}</h1>
        <h1 class="end info-color">
          DUE (VAT incl) :
          {{ formatPrice(due + (due * company.vatRate) / 100) }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import CreditNoteModule from "@/store/modules/credit-note";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../../../views/helpers";
import { isNil } from "lodash";
import moment from "moment";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapInvoiceGetters } = createNamespacedHelpers("invoice");
const { mapGetters: mapCreditNoteGetters } = createNamespacedHelpers(
  "creditNote"
);
const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "creditNote",
  CreditNoteModule
);

export default {
  name: "credit-note-header",
  components: {},
  props: {
    companyId: {
      type: Number,
      required: false
    },
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  computed: {
    ...mapAuthGetters(["firstName", "lastName"]),
    ...mapInvoiceGetters(["company", "invoice", "balance", "due"]),
    ...mapCreditNoteGetters([
      "draftedAt",
      "state",
      "finalizedAt",
      "draftedByFullName",
      "finalizedByFullName"
    ]),
    creditNoteState() {
      return this.isCreate ? "UNSAVED DRAFT" : this.state;
    },
    auditTrails() {
      let auditTrails = [];
      if (isNil(this.$store.state.creditNote.model.id)) {
        auditTrails.push(
          `Drafted @ ${this.formatDate(this.draftedAt)} by ${this.firstName} ${
            this.lastName
          }`
        );
      } else {
        if (this.state === "DRAFT" || this.state === "OPEN") {
          auditTrails.push(
            `Drafted @ ${this.formatDate(this.draftedAt)} by ${
              this.draftedByFullName
            }`
          );
        }
        if (this.state === "OPEN") {
          auditTrails.push(
            `Finalized @ ${this.formatDate(this.finalizedAt)} by ${
              this.finalizedByFullName
            }`
          );
        }
      }
      return auditTrails;
    }
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR"
      });
      return !isNil(price) ? formatter.format(price) : null;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  },
  ...detailViewLifecycleMethods
};
</script>
<style scoped>
.header-wrapper-grey {
  background-color: #e0e0e0;
}
.header-wrapper-blue {
  background-color: #e3f2fd;
}
.general-info {
  display: grid;
  grid-template-columns: 3% 97%;
  grid-template-rows: 100%;
}
.credit-note-icon {
  grid-column-start: 1;
  justify-self: center;
  align-self: center;
  font-size: 36px;
}
.credit-note-info {
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  display: grid;
  align-self: center;
}
.invoice-info {
  border: solid #027dd6 2px !important;
  background-color: #e3f2fd !important;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 33% 33% 33%;
}
.info-row {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}
.row {
  display: flex;
}
.end {
  justify-self: end;
}
.info-color {
  color: #027dd6;
}
.blacklisted {
  color: red;
}
.autofill {
  height: 40px;
}
mark {
  background-color: unset;
}
.info-row h1 {
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
}
</style>

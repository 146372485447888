<template>
  <div>
    <v-card color="white">
      <v-toolbar dark color="primary" dense>
        <v-icon class="white--text">fas fa-file-invoice-dollar</v-icon>
        <v-toolbar-title class="white--text"
          ><span class="primary--text text--lighten-2"> CREATE </span>NEW DRAFT
          CREDIT NOTE</v-toolbar-title
        >
      </v-toolbar>
      <credit-note @savable="savable" />
    </v-card>
    <v-fab-transition>
      <v-btn
        v-show="canSave"
        :loading="loading"
        color="success"
        dark
        fixed
        bottom
        right
        fab
        @click="createDraft"
      >
        <v-icon class="display-center">fas fa-check</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script type="text/babel">
import creditNoteModule from "@/store/modules/credit-note";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import CreditNote from "../../../components/invoice/credit-note/CreditNote";
import { SAVE } from "../../../store/templates/crud/action-types";
import { isNil } from "lodash";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "creditNote",
  creditNoteModule
);

export default {
  name: "credit-note-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: { CreditNote },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    canSave: false,
    breadcrumbs: {},
    loading: false
  }),
  created() {
    this.$store.commit("creditNote/setVatRate", this.company.vatRate);
  },
  computed: {
    invoiceId() {
      return this.$store.getters["invoice/id"];
    },
    company() {
      return this.$store.getters["invoice/company"];
    },
    isCompanyView() {
      return !isNil(this.companyId);
    }
  },
  methods: {
    savable(val) {
      this.canSave = val;
    },
    async createDraft() {
      try {
        this.loading = true;
        const id = await this.$store.dispatch(
          `creditNote/${SAVE}`,
          this.invoiceId
        );
        this.$router.push({
          name: this.isCompanyView
            ? "company.invoices.credit-notes.show"
            : "invoices.credit-notes.show",
          params: { creditNoteId: id, companyId: this.companyId }
        });
      } catch (e) {
        this.loading = false;
        this.$emit("save-failure", { exception: e });
      }
    }
  },
  ...createViewLifecycleMethods
};
</script>
<style scoped>
.display-center {
  display: flex;
}
</style>

<template>
  <v-card>
    <v-dialog v-model="internalDialog" max-width="500">
      <v-card>
        <div class="pt-3 px-5">
          <p>
            Finalizing this draft credit note is an
            <strong>irreversible</strong> action and the affiliate's billing
            contacts will be notified.
          </p>
          <p>Please make sure everything is in order.</p>
          <div
            v-if="company.state === 'BLACKLISTED'"
            class="pa-1 blacklisted mb-5"
          >
            <p class="ma-0">
              The affiliate you are trying to create a credit note for is
              blacklisted
            </p>
          </div>
          <v-layout row justify-space-around>
            <v-btn
              color="white"
              depressed
              :loading="loading"
              @click="internalDialog = false"
              >CANCEL</v-btn
            >
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="finalize"
              >Finalize</v-btn
            >
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "finalize-credit-note-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("close");
      }
    },
    creditNoteId() {
      return this.$store.getters["creditNote/id"];
    },
    company() {
      return this.$store.getters["invoice/company"];
    },
    invoiceId() {
      return this.$store.getters["invoice/id"];
    },
    isCompanyView() {
      return !isNil(this.companyId);
    }
  },
  methods: {
    async finalize() {
      try {
        this.loading = true;
        await this.$store.dispatch("creditNote/finalize", this.creditNoteId);
        this.$emit("close");
        this.loading = false;
        window.Echo.private(`CreditNote.${this.creditNoteId}`).stopListening(
          ".credit-note.pdf.generated"
        );
        this.$router.push({
          name: this.isCompanyView ? "company.invoices.show" : "invoices.show",
          params: { invoiceId: this.invoiceId, companyId: this.companyId }
        });
      } catch (e) {
        this.loading = false;
        this.$emit("save-failure", { exception: e });
      }
    }
  }
};
</script>
<style scoped>
p {
  text-align: center;
  font-size: 22px;
}
.blacklisted {
  background-color: var(--v-warning-base);
  border: solid #bc5a01 2px !important;
  color: #bc5a01;
}
</style>

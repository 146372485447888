<template>
  <div class="pa-3">
    <credit-note-header :is-create="isCreate" />
    <credit-note-items @savable="savable" :is-create="isCreate" />
    <credit-note-totals :is-create="isCreate" />
  </div>
</template>

<script type="text/babel">
import CreditNoteHeader from "./CreditNoteHeader";
import CreditNoteItems from "./CreditNoteItems";
import CreditNoteTotals from "./CreditNoteTotals";
export default {
  name: "credit-note",
  components: { CreditNoteTotals, CreditNoteItems, CreditNoteHeader },
  props: {
    companyId: {
      type: Number,
      required: false
    },
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  methods: {
    savable(val) {
      this.$emit("savable", val);
    }
  }
};
</script>

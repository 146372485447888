<template>
  <div class="grid-container">
    <v-text-field
      v-model="description"
      v-validate="'required|max:255'"
      label="Description"
      autocomplete="not-my-description"
      :counter="255"
      :error-messages="errors.collect('description')"
      data-vv-name="description"
      required
    />
    <v-text-field
      v-model="quantity"
      type="number"
      class="input-right"
      v-validate="'required|integer|min_value:1'"
      autocomplete="not-my-quantity"
      :error-messages="errors.collect('quantity')"
      data-vv-name="quantity"
      required
    />
    <v-text-field
      v-model="unitPrice"
      class="input-right"
      v-validate="'required|min_value:0.01|exceedInvoiceTotalEdit'"
      autocomplete="not-my-unit-price"
      :error-messages="errors.collect('unitPrice')"
      data-vv-name="unitPrice"
      name="unitPrice"
      prefix="€"
      label="Price"
    />
    <div class="btn-grid">
      <v-btn color="grey" class="small-button" @click="remove"
        ><v-icon>fas fa-times</v-icon></v-btn
      >
      <v-btn color="primary" class="large-button" @click="confirm"
        >CONFIRM</v-btn
      >
    </div>
  </div>
</template>

<script type="text/babel">
import { cloneDeep } from "lodash";
import { Validator } from "vee-validate";
import store from "@/store";

let tempItem = {
  unitPrice: 0,
  description: null,
  quantity: 0
};
let index = null;
// eslint-disable-next-line
const exceedInvoiceTotal = value =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async resolve => {
    const invoiceDue = store.getters["invoice/due"];
    let items = cloneDeep(store.getters["creditNote/items"]);
    items.splice(index, 1);
    let creditNoteTotalAmount = items.reduce(
      (t, { totalPrice }) => t + totalPrice,
      0
    );
    const tempItemTotalPrice = tempItem.unitPrice * tempItem.quantity;
    creditNoteTotalAmount += tempItemTotalPrice;
    if (invoiceDue >= creditNoteTotalAmount) {
      return resolve({
        valid: true
      });
    }

    return resolve({
      valid: false,
      data: {
        message: `The credit note exceeds the invoice total`
      }
    });
  });

Validator.extend("exceedInvoiceTotalEdit", {
  validate: exceedInvoiceTotal,
  getMessage: (fields, params, data) => data.message
});

export default {
  name: "credit-note-item-edit",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    tempItem: null
  }),
  watch: {
    item: {
      handler(newVal) {
        this.tempItem = cloneDeep(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    unitPrice: {
      get() {
        return this.tempItem.unitPrice;
      },
      set(val) {
        this.tempItem.unitPrice = val;
        this.tempItem.totalPrice = val * this.tempItem.quantity;
        tempItem = this.tempItem;
      }
    },
    description: {
      get() {
        return this.tempItem.description;
      },
      set(val) {
        this.tempItem.description = val;
        tempItem = this.tempItem;
      }
    },
    quantity: {
      get() {
        return this.tempItem.quantity;
      },
      set(val) {
        this.tempItem.quantity = val;
        this.tempItem.totalPrice = val * this.tempItem.unitPrice;
        tempItem = this.tempItem;
      }
    }
  },
  methods: {
    async confirm() {
      index = this.index;
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("creditNote/setItem", {
        index: this.index,
        item: this.tempItem
      });
      this.$emit("confirm");
    },
    remove() {
      this.$emit("confirm");
    }
  }
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 7fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgray;
  align-items: center;
}
.btn-grid {
  display: grid;
  grid-template-columns: 8fr 2fr;
  align-items: center;
}
.input-right >>> input {
  text-align: right;
}
.small-button {
  min-width: 0;
  width: 50px;
}
.large-button {
  min-width: 0;
  width: 120px;
}
</style>
